<template>
  <div class="pagination-container">
    <div class="container">
      <div class="photo" v-for="(item, index) in photos" :key="index">
        <div
          v-if="item.type === 'upload'"
          class="title2"
          @click="showUploadDialog"
        >
          <a-icon type="plus" />
          <div>上传社区容貌</div>
        </div>
        <div
          v-if="item.type === 'photo'"
          class="imgs"
          @click="showEditDialog(item)"
        >
          <img :src="item.fileIds" alt="" />
          <div class="title">
            <span>{{ item.environmentName }}</span>
            <span style="color: #9fa7b8">{{ item.updateTime }}</span>
            <a-button
              class="delete-button"
              @click.stop="showDeleteDialog(item.id)"
            >
              <a-icon type="delete" />
            </a-button>
          </div>
        </div>
      </div>
    </div>
    <!-- <a-pagination
      v-model="current"
      :page-size-options="pageSizeOptions"
      :total="updatetotal"
      show-size-changer
      :page-size="pageSize"
      @showSizeChange="onShowSizeChange"
      @change="onPageChange"
    >
      <template slot="buildOptionText" slot-scope="props">
        <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
        <span v-if="props.value === '50'">全部</span>
      </template>
    </a-pagination> -->
    <a-pagination
      v-model="current"
      show-less-items
      :total="updatetotal"
      :page-size="pageSize"
      @showSizeChange="onShowSizeChange"
      @change="onPageChange"
    />
    <!-- el-dialog 弹窗 -->
    <el-dialog
      :visible.sync="uploadDialogVisible"
      :title="isEditing ? '编辑社区容貌' : '上传社区容貌'"
      width="30%"
      :z-index="10"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <ax-form ref="formBox" :formBuilder="formBuilder">
        <!-- 在这里添加你的上传表单或其他内容 -->
        <div
          slot="fileIds"
          slot-scope="{ record }"
          v-decorator="[
            record.model,
            {
              rules: record.rules,
            },
          ]"
        >
          <imageUpload
            ref="img"
            :initial-file="currentPhoto ? currentPhoto.fileIds : null"
            :businessId="businessId"
          ></imageUpload>
        </div>
      </ax-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="handleUpload">{{
          isEditing ? "保存" : "上传"
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisible"
      width="23%"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <div style="font-size: 0.09rem">
        <div style="display: flex; align-items: center">
          <a-icon
            type="question-circle"
            style="
              color: #faad14;
              float: left;
              margin-right: 16px;
              font-size: 22px;
            "
          />
          <span
            style="
              display: block;
              overflow: hidden;
              color: rgba(0, 0, 0, 0.85);
              font-weight: 500;
              font-size: 16px;
              line-height: 1.4;
            "
            >操作确认</span
          >
        </div>
        <span
          class="shanchu"
          style="
            margin-left: 38px;
            margin-top: 8px;
            color: rgba(0, 0, 0, 0.65);
            font-size: 14px;
          "
          >是否删除当前内容?</span
        >
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmDeletePhoto">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import imageUpload from "./ax-image-modal copy/src/image-upload.vue";
import api from "./api";
const formList = [
  {
    label: "社貌名称",
    type: "",
    model: "environmentName",
    options: { placeholder: "请输入", showTime: true, maxLength: 20 },
    col: { span: 16 },
    rules: [
      {
        required: true,
        message: "活动名称不能为空",
      },
    ],
  },
  {
    label: "社貌图片",
    type: "",
    model: "fileIds",
    col: { span: 24 },
    rules: [{ required: true, message: "请选择图片" }],
  },
];
export default {
  components: { imageUpload },
  data() {
    return {
      api,
      fileIds: [],
      img: "",
      total: 0,
      photos: [{ type: "upload" }], // 添加上传按钮的占位符元素
      // pageSizeOptions: ["15"],
      current: 1,
      pageSize: 15,
      businessId: "",
      uploadDialogVisible: false, // 控制弹窗显示状态,
      dialogImageUrl: "",
      isEditing: false, // 控制是否为编辑模式
      currentPhoto: null, // 当前编辑的照片数据
      dialogVisible: false,
      photoToDeleteId: null, // 用于存储待删除照片的ID
      disabled: false,
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 125 },
        { col: { span: 8 } }
      ),
    };
  },
  computed: {
    paginatedPhotos() {
      const start = (this.current - 1) * this.pageSize;
      const end = this.current * this.pageSize;
      return this.photos.slice(start, end);
    },
    updatetotal() {
      return this.total;
    },
  },
  watch: {
    businessId(newval) {
      this.businessId = newval;
    },
  },
  mounted() {
    this.getlist();
  },
  methods: {
    onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
      this.current = 1; // 重置为第一页
    },
    onPageChange(page) {
      this.current = page;
      this.getlist(); // 重新获取数据
    },
    //获取照片墙数据
    async getlist() {
      const obj = {
        pageNo: this.current, // 使用当前页数
        pageSize: 15,
      };
      const res = await api.list(obj);
      // 将获取到的数据赋值给photos数组
      let photoData = res.data.records.map((record) => {
        return {
          type: "photo",
          id: record.id, // 假设record中包含唯一的ID
          fileIds: record.fileInfos?.[0]?.url ?? "",
          environmentName: record.environmentName,
          updateTime: record.updateTime,
        };
      });
      if (this.current === 1) {
        photoData = [{ type: "upload" }, ...photoData];
      }
      this.photos = photoData;
      // 更新total属性
      this.total = res.data.total;
      this.current = res.data.current;
    },
    //删除弹窗
    showDeleteDialog(id) {
      this.photoToDeleteId = id;
      this.dialogVisible = true;
    },
    //删除操作
    async confirmDeletePhoto() {
      try {
        console.log("删除的照片ID:", this.photoToDeleteId);
        const res = await api.deleteById(this.photoToDeleteId);
        if (res.status === 200) {
          this.$message.success("删除成功");
          this.getlist(); // 重新获取列表
        } else {
          this.$message.error("删除失败");
        }
      } catch (error) {
        console.error("删除失败:", error);
        this.$message.error("删除失败");
      } finally {
        this.dialogVisible = false; // 关闭弹窗
        this.photoToDeleteId = null; // 重置待删除照片ID
      }
    },
    // 上传弹窗
    showUploadDialog() {
      this.fileIds = [];
      this.isEditing = false;
      this.currentPhoto = null;
      this.uploadDialogVisible = true;
      // 重置表单和图片上传组件
      this.$nextTick(() => {
        this.$refs.img.removeFileList();
      });
    },
    // 打开编辑弹窗
    async showEditDialog(photo) {
      this.isEditing = true; // 表示当前操作是编辑已有照片
      this.currentPhoto = photo; // 将当前照片的信息存储到 currentPhoto
      this.uploadDialogVisible = true; // 显示编辑弹窗

      // 调用接口获取图片详细信息
      try {
        const res = await api.getById(photo.id); // 根据照片ID获取详细信息
        this.businessId = photo.id; // 设置 businessId 为当前照片的ID
        // console.log(this.businessId);
        // console.log(photo.fileIds);
        if (res.status === 200) {
          // 赋值表单，将获取到的数据填入表单
          this.$refs.formBox.setFieldsValue({
            ...res.data,
            fileIds: photo.fileIds, // 设置 fileIds 的初始值
          });
        } else {
          this.$message.error("获取图片详细信息失败");
        }
      } catch (error) {
        console.error("获取图片详细信息失败:", error);
        this.$message.error("获取图片详细信息失败");
      }
    },

    // 上传和编辑方法
    async handleUpload() {
      // 根据isEditing判断是新增还是编辑
      if (this.isEditing) {
        // 编辑逻辑
        this.$refs.formBox.form.validateFields(async (err, value) => {
          if (err) return;
          this.fileIds = [];
          const files = this.$refs.img.getFile();
          const files1 = this.$refs.img.getFileListAll();
          const fileInfos = this.$refs.img.getfileDetail();
          value.fileInfos = [fileInfos];
          console.log(files, "195");
          if (files1.length > 0) {
            const ids = files1.map((item) => {
              return item.uid;
            });
            const res = await api.del(ids.join(","));
          }
          files.map((item) => {
            if (item.response) {
              this.fileIds.push(item.response.data.id);
            } else {
              this.fileIds.push(item.uid);
            }
          });
          value.fileIds = [...new Set([...this.fileIds])];
          value.id = this.currentPhoto.id; // 添加编辑的ID
          console.log(value);
          try {
            const res = await api.update(value); // 假设有一个更新API
            if (res.status === 200) {
              this.$message.success("编辑成功");
              this.saveType = false;
              this.uploadDialogVisible = false; // 上传完成后关闭弹窗
              this.getlist(); // 重新获取列表
            } else {
              this.$message.error("编辑失败");
              this.saveType = true;
            }
          } catch (err) {
            console.log(error);
            this.$message.error("编辑失败");
            this.saveType = false;
          }
        });
      } else {
        // 新增逻辑
        this.$refs.formBox.form.validateFields(async (err, value) => {
          if (err) return;
          this.fileIds = [];
          const files = this.$refs.img.getFile();
          const files1 = this.$refs.img.getFileListAll();
          const fileInfos = this.$refs.img.getfileDetail();
          value.fileInfos = [fileInfos];
          console.log(files, "195");
          if (files1.length > 0) {
            const ids = files1.map((item) => {
              return item.uid;
            });
            const res = await api.del(ids.join(","));
          }
          files.map((item) => {
            if (item.response) {
              this.fileIds.push(item.response.data.id);
            } else {
              this.fileIds.push(item.uid);
            }
          });
          value.fileIds = [...new Set([...this.fileIds])];
          console.log(value);
          try {
            const res = await api.add(value);
            if (res.status === 200) {
              this.$message.success("新增成功");
              this.saveType = false;
              this.uploadDialogVisible = false; // 上传完成后关闭弹窗
              this.getlist(); // 重新获取列表
            } else {
              this.$message.error("新增失败");
              this.saveType = true;
            }
          } catch (err) {
            console.log(error);
            this.$message.error("新增失败");
            this.saveType = false;
          }
        });
      }
    },
    //关闭上传和编辑弹窗
    closeDialog() {
      this.businessId = "";
      this.uploadDialogVisible = false;
    },
  },
};
</script>


<style lang="less" scoped >
.pagination-container {
  position: relative;
  width: 100%;
  height: 100%;
}

::v-deep .ant-pagination {
  position: absolute;
  bottom: 1%;
  right: 1%;
}

.container {
  width: 100%;
  height: 95%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
  align-content: flex-start;
}

.photo {
  width: 17%;
  height: 31%;
  margin-left: 1.5%;
  margin-right: 1.5%;
  margin-bottom: 1.085%;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(53, 65, 97, 0.15);
  border-radius: 10px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative; /* 添加这行 */
  .title2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 1px dashed #c9cdd9;
    border-radius: 10px;
  }
  .imgs {
    width: 100%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      height: 70%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .delete-button {
      position: absolute;
      top: 0;
      right: 0;
      background: none;
      border: none;
      cursor: pointer;
      background: rgb(255, 255, 255);
      border-radius: 8px;
      width: 35px;
      height: 35px;
      margin: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #2d3138;
      opacity: 0; /* 初始状态下隐藏 */
      transition: opacity 0.3s; /* 添加过渡效果 */
    }
  }
}

.photo:hover .delete-button {
  opacity: 1; /* 鼠标悬停时显示 */
}

.title {
  width: 90%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  font-style: normal;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    margin: 2% 5% 4% 10%;
    font-weight: 400;
    font-size: 14px;
    color: #2d3138;
    line-height: 18px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

::v-deep .el-upload__tip {
  padding-left: 10px;
}

::v-deep .ant-form input[type="file"] {
  display: none !important;
}

::v-deep .el-dialog {
  border-radius: 20px !important;
}
</style>

